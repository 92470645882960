<template>
  <div class="">
     <LicIndVlrNotForm v-if="!selectedLicitacion"/>
     <div v-else class="flex justify-center">
        <div class="p-card p-5">
          <h5 class="text-center my-2 font-bold uppercase text-gray-600">
            Indicadores financieros
          </h5>
          <divider/>
          <form>
            <div class="grid grid-cols-3 gap-4 mb-2" v-for="indicador in listIndicadores" :key="indicador.code">
              <label class="p-label my-auto mr-2 text-gray-600">
                {{indicador.nombre}}:
              </label>
              <InputText :placeholder="indicador.code"
                         v-model="indicador.valor"
              />
              <Dropdown v-model="indicador['operation']"
                        :options="indiceList"
                        option-label="name"
                        option-value="vlr"
                        placeholder="Seleccione"
                        class="p-inputtext-sm w-full"
              />
            </div>
          </form>
        </div>
     </div>
  </div>
</template>
<script>
import { defineAsyncComponent, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'LicIndVlrForm',
  components: {
    LicIndVlrNotForm: defineAsyncComponent(() => import('./notForm.vue'))
  },
  setup () {
    /** instancias **/
    const store = useStore()
    /** data **/
    /** methods **/
    /** computed **/
    const listIndicadores = computed(() => {
      return store.getters['storelicitacionesIndicadoresValores/getListIndicadores']
    })
    const selectedLicitacion = computed(() => {
      return store.getters['storelicitacionesIndicadoresValores/getSelectedLicitacion']
    })
    const indiceList = computed(() => {
      return [
        { name: 'Igual', vlr: '===' },
        { name: 'Menor', vlr: '<' },
        { name: 'Menor y igual', vlr: '<=' },
        { name: 'Mayor', vlr: '>' },
        { name: 'Mayor  y igual', vlr: '>=' }
      ]
    })
    /** watch **/
    /** return **/
    return {
      listIndicadores,
      selectedLicitacion,
      indiceList
    }
  }
}
</script>

<style scoped>

</style>
